@import "~scss/variables";

.contact-form {
  .form-row {
    width: 100%;
    margin: 1rem 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    label {
      display: none;
    }
    .form-column {
      position: relative;
      width: 100%;
      & + .form-column {
        margin-top: 1rem;
      }
    }
    @include tablet {
      flex-direction: row;
      .form-column {
        & + .form-column {
          margin: 0;
        }
        width: calc(50% - 1rem);
      }
    }
  }
  .affiliations {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    @include tablet {
      flex-direction: row;
    }
  }
  .affiliation {
    position: relative;

    .radio-with-border {
      @include inputBorder;
      cursor: pointer;
      margin-right: 0.5rem;
    }
    label {
      font-size: 0.8889rem;
      cursor: pointer;
    }
    input[type="radio"] {
      opacity: 0;
      display: none;
      &:checked + label .radio-with-border {
        background-color: var(--black);
      }
    }
    @include tablet {
      & + .affiliation {
        margin-left: 1.2rem;
      }
    }
  }
  input[type="email"],
  input[type="text"],
  textarea {
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    font-size: 1rem;
    border: 2px solid;
    background: transparent;
    color: var(--white);
    font-family: var(--sans);
    &::placeholder {
      color: var(--white);
    }
    width: 100%;
    border-radius: 0;
  }
  textarea {
    height: 400px;
  }
  [type="submit"] {
    font-family: var(--sans);
    background: var(--white);

    border: none;
    border-radius: 0;
    text-transform: uppercase;
    padding: 0.75rem 4rem;
    border: 2px solid var(--dark-gray);
    font-size: 1rem;
    transition: 0.5s;
    &:hover {
      background-color: var(--dark-gray);
      color: var(--white);
    }
  }
  .button--container {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

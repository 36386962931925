@import "~scss/variables";

.location__tile.tile {
  --location-border: 20px;
  height: 100%;

  @include tablet {
    height: auto;
  }
  @include desktop {
    --location-border: 40px;
    margin-bottom: 3rem;
  }

  position: relative;
  width: 100%;
  margin-bottom: 3rem;

  .image {
    width: calc(100% - var(--location-border));
    padding-bottom: 56%;
    height: 0;
    border: 2px solid var(--white);
    border-top: none;
    border-left: none;
    position: relative;
  }
  .location__heading {
    color: var(--black);
    position: relative;
    z-index: 2;
    padding: 1rem 0;
    h1 {
      margin: 0;
      font-size: 1.2rem;
    }
    &:before {
      z-index: 1;
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - var(--location-border) - 4px);
      background-color: var(--yellow);
      height: 0;
      padding-bottom: 75%;
      @include large {
        padding-bottom: 80%;
      }
    }
    .location__name {
      position: relative;
      z-index: 3;
      margin-left: var(--location-border);
    }
  }

  .location__details {
    position: relative;
    z-index: 3;
    background-color: var(--green);
    margin-left: var(--location-border);

    border: 2px solid var(--white);
    box-sizing: border-box;
    height: auto;
    padding: 0;
    height: 100%;
    @include tablet {
      height: auto;
    }
  }
  .location__footer {
    padding: 0 1rem;
    p {
      line-height: 1.33;
    }
  }

  @include tablet {
    width: calc(50% - 1rem);
    .location__heading {
      h1 {
        font-size: 1.66rem;
      }
    }
    .location__details {
      // height: 0;
      // padding-bottom: 80%;
    }
  }
  @include desktop {
    width: calc(50% - 1rem);
  }
}

.section-nav {
  position: absolute;
  bottom: var(--location-border);
  left: 0;
  padding: 0 1rem;

  display: flex;
  justify-content: space-between;
  width: 100%;
  box-sizing: border-box;
  align-items: center;
  a {
    transition: 0.25s;
    color: var(--black);
    &:hover {
      color: var(--green);
    }
  }
  @include desktop {
    padding: 0 3rem;
  }
}

@import "~scss/variables";

.tiles {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
  &.container {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  // &::after {
  //   content: "";
  //   flex: auto;
  // }

  @include mobileonly {
    &.container {
      padding: 0;
    }
  }
  @include desktop {
    &.container {
      padding: 1rem 0;
    }
  }
}
.tile {
  width: calc(100% + 2rem);

  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  color: var(--white);
  position: relative;

  .vert-2 {
    line-height: 1;
    box-sizing: content-box;
    writing-mode: vertical-rl;
    background-color: var(--dark-gray);
    width: var(--tile-border-width) !important;
    transform: rotate(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 2px solid var(--white);
    border-bottom: 2px solid var(--white);
    flex-shrink: 0;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 0.888rem;
    &.after {
      position: absolute;
      // right: calc(-1 * var(--tile-border-width));
      right: 0;
      bottom: calc(var(--tile-border-width) + 2px);
      height: 100%;
      padding-top: 50%;
      border: none;
      border-right: 2px solid var(--white);
      @include mobileonly {
        display: none;
      }
    }
  }
  .vert {
    font-weight: 500;
    height: var(--tile-border-width);
    position: absolute;
    background-color: var(--dark-gray);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 100%;
    transform-origin: 100% 0;
    transform: rotate(-90deg);
    color: var(--white);
    border-bottom: 2px solid var(--white);
    box-sizing: border-box;

    &.after {
      display: none;
    }
  }
  .tile__mid {
    z-index: 1;
    // border: 2px solid var(--white);
    // padding-left: var(--tile-border-width);
    position: relative;
    display: flex;
    flex-direction: row;
    box-sizing: content-box;
  }
  .mid__wrap {
    flex-shrink: 1;
    width: 100%;
    border-top: 2px solid var(--white);

    @include mobileonly {
      .heading {
        width: calc(100% - 1 * var(--tile-border-width) - 2px);
      }
    }
  }
  .eyebrow,
  .vert {
    text-transform: uppercase;
    font-size: 0.88rem;
  }
  .eyebrow {
    position: relative;
    font-weight: 700;
    background-color: var(--yellow);
    color: var(--black);
    height: var(--tile-border-width);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding-left: 1rem;
    margin-left: calc(var(--tile-border-width) + 2px);
    width: calc(100% - 2 * var(--tile-border-width) - 6px);
    z-index: 1;
    box-sizing: border-box;
    @include tablet {
      width: calc(100% - var(--tile-border-width) - 2px);
    }
  }
  .image {
    height: 188px;
    background: var(--black);
    position: relative;
    border-top: 2px solid var(--white);
    box-sizing: border-box;
    margin-right: calc(var(--tile-border-width) + 2px);
    .tile-count-2 & {
      height: 344px;
    }
    .image-block {
      position: absolute;
      height: 100%;
      overflow: hidden;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
  }
  .heading,
  .text {
    padding: 0.78rem 1.1rem;
  }
  .heading {
    background-color: var(--green);
    min-height: 80px;
    font-size: 1.11rem;
    box-sizing: border-box;
    font-weight: 700;
    line-height: 1.2;
  }
  .source {
    width: calc(116px + 188px);
    .tile-count-2 & {
      width: calc(116px + 344px);
    }
  }

  & + .tile {
    margin-top: 1rem;
  }
  @include mobileonly {
    height: 100%;
  }
  @include tablet {
    width: calc(50% - 1rem);
    margin: 0 0 3rem 0;
    &:nth-child(2n) {
      margin-right: 0;
    }
    & + .tile {
      margin-top: 0;
    }
    .vert.after {
      display: block;
      right: 0;
      width: calc(116px + 188px);
      border-bottom: 0;
      border-top: 2px solid var(--white);
      z-index: 100;
      .tile-count-2 & {
        width: calc(116px + 344px);
      }
    }
    // .image,
    // .text {
    //   //margin-right: var(--tile-border-width);
    // }
  }
  @include desktop {
    margin: 0 0 4rem 0;
  }
  @include large {
    margin: 0 1rem 4rem 0;
    .tiles:not(.tile-count-2) & {
      width: calc(33% - 2rem);

      &:nth-child(2n) {
        margin-right: 0;
      }
      &:nth-child(3n-1):last-child {
        margin-right: calc(33% + 2rem);
      }
      &:nth-child(3n) {
        margin-right: 0;
      }
      .slideout-content & {
        width: calc(50% - 3rem);
      }
    }
  }
}
.tile__footer {
  display: flex;
  flex-direction: row;
  position: relative;
  box-sizing: content-box;

  .text {
    border: 2px solid var(--white);
    border-left: none;
    border-bottom: none;
    box-sizing: border-box;
    background: var(--green);
    box-sizing: border-box;
    min-height: 144px;
    width: calc(100% - 2 * var(--tile-border-width) - 2px);
  }
  @include mobileonly {
    flex: 1;
  }
}

.more {
  position: absolute;
  color: var(--black);
  padding: 0.66rem 1.3rem;
  background-color: var(--white);
  display: inline-block;
  bottom: -2px;
  left: -2px;
  font-weight: 700;

  font-size: 0.888rem;
  text-transform: uppercase;
  &:after {
    transition: 0.25s;
    content: "→";
    margin-left: 0.5rem;
    display: inline-block;
  }
  .tile:hover &:after {
    transform: translateX(8px);
  }
}

.tile__link {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
}

.ctas {
  display: flex;
  width: 100%;
  align-items: center;
  .cta a {
    display: inline-block;
  }
  .cta + .cta {
    margin-left: 1rem;
  }
}

@import "~scss/variables";
.team-member__header {
  display: flex;
  flex-direction: column;
  margin-bottom: 2rem;
  @include tablet {
    flex-direction: row;
  }
}
.team-member__image {
  width: 100%;
  padding-bottom: 100%;
  margin-bottom: 1rem;

  height: 0;
  position: relative;

  .image-block {
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      object-fit: cover;
      height: 100%;
    }
  }
  @include tablet {
    width: 40%;
    padding-bottom: 40%;
    margin-bottom: 0;
  }
}
.team-member__details {
  h1 {
    font-size: 3rem;
    line-height: 1;
    margin: 1rem 0;
  }
  .meta {
    font-size: 1.2rem;
  }
  .location {
    border-top: 1px solid var(--yellow);
    border-bottom: 1px solid var(--yellow);
    .site-name {
      font-weight: 700;
    }
    padding: 0.5rem 0;
    margin: 1rem 0 0;
  }
  @include tablet {
    margin-left: 3rem;
  }
}

@import "~scss/variables";

.hero {
  width: 100%;
  min-height: 300px;
  max-height: 800px;
  height: calc(100vh - 135px);
  background-color: var(--dark-gray);
  color: var(--white);
  position: relative;
  overflow: hidden;

  .hero__image {
    z-index: 1;
    .image-block {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      img {
        height: 100%;
        object-fit: cover;
      }
    }
    &:after {
      content: "";
      height: 50%;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      background: linear-gradient(
        180deg,
        rgba(41, 36, 28, 0.0001) 0%,
        rgba(41, 36, 28, 0.9) 100%
      );
    }
  }
  .heading {
    padding: 1rem;
    z-index: 2;
    width: 80%;
    max-width: 800px;
    margin-bottom: 2rem;
    h1 {
      font-size: 2.5rem;
      line-height: 1;
      margin-bottom: 1rem;
    }
    .subheading {
      font-size: 1.3rem;
      margin: 1rem 0 1rem;
      p {
        line-height: 1.15;
      }
    }
    @include tablet {
      h1 {
        font-size: 3rem;
      }
    }
    @include desktop {
      h1 {
        font-size: 4.5rem;
      }
      .subheading {
        font-size: 1.8rem;
      }
    }
    @include large {
      h1 {
        font-size: 5.5rem;
      }
    }
    @include xl {
      h1 {
        font-size: 6rem;
      }
    }
  }
  .outer {
    position: relative;
    z-index: 2;
    border-left: 2px solid var(--white);

    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;

    position: absolute;
    top: 0;
    height: 100%;
  }

  @include desktop {
    height: 100vh;
  }
}

@import "~scss/variables";
.contentRow {
  z-index: 1;
  position: relative;
}

@include mobileonly{
  .order-1{ order: 2;}
  .order-2{ order: 1;}
}
.flexBlock {
  // padding: 2rem 0;
  margin: var(--border-width) auto;
  
  &:last-child{
    margin-bottom: 0;
  }

  .contentRow {
    display: flex;
  }
  .page &{
    margin: 0 auto;
  }

  .contentRow {
    .container {
      padding: 0; 
      margin: 0 auto;
      // border: 1px dotted gray;
      position: relative;
      display: flex;
      flex-direction: column;

      .page > & {
        width: calc(100% - 2 * var(--border-width));
      }

      @include tablet {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
      
    }
    

    .page > &{
      .container{
        padding: 0 var(--border-width); 
        margin: var(--border-width) auto;
      }
      
      @include tablet{
        &.neg-top-margin .container {
          margin-top: calc(-1 * var(--border-width) + 1px);
        }
        
      }
      @include large {
        .contentColumn:nth-child(2) > .img.has-border {
          // margin-left: calc(-1 * var(--border-width));
          transform: translate(calc(-1 / 2 * var(--border-width) - 3px), 0);
        }
      }
    }
    
    

    
  }
  .sidebarColumn {
    flex-basis: auto;
    flex-grow: 0;
  }
  .contentColumn {
    position: relative;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    align-items: center;

    .image-block{
      @include mobileonly{
        margin-top: 1rem;
      }
    }
    &:first-child .img.has-border{
      img{
        border-right: 1px solid var(--white);
      }
    }

    .img{
      width: 100%;
      max-width: 500px;
      &.has-border{
        max-width: none;
      }
    }
    .img:not(.has-border){
      align-self: center;
    }
    // margin-bottom: 2rem;
    .img.has-border{
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        position: absolute;
        top: 0;
        left: 0;
      }
      @include desktop{
          margin-left: 20px;
        }
    }
    
    &:only-child .img{
        margin: 0 auto;
    }

    @include mobileonly{
      .img.has-border{
        margin: 2rem 0;
      }
    }
    .img.has-border .image-block {
      position: relative;
      margin-top: var(--border-width);
      // border: 2px solid var(--white);
      img {
        z-index: 2;
        border-bottom: 1px solid var(--white);
        border-left: 1px solid var(--white);
      }
    }
    & > .img.has-border .image-block:before,
    & > .img.has-border .image-block:after {
      display: block;
      position: absolute;
      content: "";
      width: calc(100% + var(--border-width));
      height: calc(100% + var(--border-width);

        
      top: calc(-1 * var(--border-width));
      left: calc(-1 * var(--border-width));
      z-index: 1;
      border: 1px solid var(--white);
    }
    &:not(:first-child) > .img.has-border .image-block:before {
      left: 0;
    }
    & > .img.has-border .image-block:after {
      right: auto;
      left: calc(-1 * var(--border-width));
      // top: var(--border-width);
      top: auto;
      bottom:calc(-1 * var(--border-width));
      z-index: 1;
    }
    
    &:first-child > .img.has-border .image-block:after {
      left: 0;
    }

    @include tablet {
      flex-basis: 50%;
      flex-grow: 1;
      max-width: calc(50% - 0.5px);
      margin-top: 2rem;
      margin-bottom: 2rem;

      .page > &{
        
        .rich-text{
          padding-left: calc(2 * var(--border-width));
        }
        &:first-child{
          .rich-text{
            padding-left: 0;
            // padding-right: calc(2 * var(--border-width));
            width: calc(100% - 2 * var(--border-width));
          }
        }
      }
      &:only-child {
        max-width: 100%;
      }
      .page > &:only-child .rich-text{
        padding: 0 1rem;
      }      
      .page > &:only-child {
        max-width: 800px;
        width: 85%;
        margin: 0 auto;
      }
    }
    @include large{
      max-width: calc(50% - 1rem);
      align-items: flex-start;margin-top: 0rem;
      margin-bottom: 0rem;
    }

  }
}
.debug {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  padding: 0.5rem;
  font-size: 0.8rem;
  position: absolute;
  top: 0;
  right: 0;
}

.align-center {
  text-align: center;
  justify-content: center;
}

.img.has-border .image-block {
  height: 0;
  padding-bottom: 83%;
  image {
    object-fit: cover;
  }
}


$colors : "green", "yellow", "blue", "red";
@each $color in $colors {
  .bg--#{$color}{ 
    .image-block:after, .image-block:before{
      background-color: var(--#{$color}) !important;
    }
  }
}

@import "~scss/variables";
.blog {
  margin: 4rem 0;

  .container {
    max-width: 1000px;
  }
  .blog__header {
    margin: 0 var(--border-width) 4rem;

    .container {
      padding: 0;
    }
  }
  .image-block {
    margin: 4rem auto;
    max-width: 1600px;
  }
  .flexBlock .contentRow {
    // max-width: 800px;
    margin: var(--border-width);
  }
  @include desktop {
    .flexBlock .contentColumn:only-child {
      // max-width: 800px;
      margin-top: 0;
      margin-left: 0;
      margin-right: 0;
      width: 100%;
      max-width: none;
    }
  }
}

@import "~scss/variables";

.slick-initialized .slick-track {
  display: flex;
}
.slick-initialized .slick-track .slick-slide {
  display: flex;
  height: auto;
  align-items: center;
  justify-content: center;

  > div {
    height: 100%;
    width: 100%;
  }
}
.carousel__heading {
  display: block;
  padding: 0.5rem;
  h1 {
    font-size: 1rem;
    text-transform: uppercase;
    margin: 0;
  }
  @include tablet {
    h1 {
      font-size: 1.4rem;
    }
  }
}
.slick-arrow {
  top: calc(50% - 30px);
  z-index: 100;
  background-color: rgba(255, 255, 255, 0.5);
  &:hover {
    background-color: rgba(255, 255, 255, 0.75);
  }

  &::before {
    display: none;
  }

  &:after {
    content: "";
    border: solid black;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
  }

  &.slick-next:after {
    transform: rotate(-45deg);
  }
  &.slick-prev:after {
    transform: rotate(135deg);
  }

  &.slick-next {
    right: 0.25rem;
  }
  &.slick-prev {
    left: 0.25rem;
  }
  @include tablet {
    &.slick-next {
      right: 0.5rem;
    }
    &.slick-prev {
      left: 0.5rem;
    }
  }
  @include large {
    display: block;
    &.slick-next {
      right: -1.5rem;
    }
    &.slick-prev {
      left: -1.5rem;
    }
  }
}

.carousel {
  position: relative;
  background-color: var(--white);
  .rp-dots {
    text-align: left;
    z-index: 2;
    position: relative;
    padding: 0 1rem;
    margin: 0;
    height: 60px;
    display: flex !important;
    align-items: center;
  }
  .container {
    padding: 0;
  }
  @include large {
    .container {
      padding: 0 var(--border-width);
    }
  }
  @include mobileonly {
    .container {
      padding: 0;
    }
  }
}
.carousel__footer {
  height: 60px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 1;
}
.carousel__slide {
  display: flex;
  align-items: center;
  // height: 500px;
  background-color: var(--white);
  flex-direction: column;
  height: 100%;
  @include tablet {
    flex-direction: row;
  }
}

.slide__content {
  padding: 1rem 2rem 0;
  order: 3;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  h1,
  h2,
  h3,
  h4 {
    &:first-child {
      margin-top: 0;
    }
  }
  p:last-child {
    margin-bottom: 0;
  }
  @include tablet {
    flex-basis: 50%;
    order: 1;
    justify-content: center;
    padding: 2rem;
    box-sizing: border-box;
  }
  @include desktop {
    padding: 4rem 6rem;
  }
}
.slide__image {
  order: 2;
  height: 25vh;
  overflow: hidden;

  background-color: var(--black);
  .image-block {
    width: 100%;
    height: 100%;

    img {
      object-fit: cover;
      height: 100%;
    }
  }
  @include tablet {
    min-height: 300px;
    width: 100%;
    height: 100%;
    flex-basis: 50%;
    flex: 1 1;
  }
}

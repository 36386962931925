@import "variables";
@import url("https://use.typekit.net/wan7ldo.css");

html,
body {
  margin: 0;
  font-size: 18px;
  color: var(--black);
  font-family: var(--sans);
  background: var(--white);
}
.container {
  max-width: 1440px;
  padding: 0 1rem;
  box-sizing: border-box;
  margin: 0 var(--border-art-width);
  margin: 0 auto;
  &.tiles {
    padding: 0 var(--border-art-width);
  }
  @include large {
    padding: 0;
    font-size: 18px;
  }
  @include xl {
    margin: 0 auto;

    &.tiles {
      max-width: 2000px;
    }
  }
}
a {
  color: var(--black);
  transition: 0.5s;
  p &:hover {
    color: var(--red);
  }
}
.colorScheme--bg-green {
  background-color: var(--green);
  color: var(--white);
  a {
    color: var(--white);
    &:hover {
      color: var(--yellow);
    }
  }
}
.colorScheme--bg-blue {
  background-color: var(--blue);
  color: var(--white);
  a {
    color: var(--white);
    &:hover {
      color: var(--yellow);
    }
  }
}
.colorScheme--bg-yellow {
  background-color: var(--yellow);
  color: var(--black);

  input[type="email"],
  input[type="text"],
  textarea {
    border-color: var(--black);
    color: var(--black);
    &::placeholder {
      color: var(--black);
    }
  }
}
.colorScheme--bg-red {
  background-color: var(--red);
  color: var(--white);
  a {
    color: var(--white);
    &:hover {
      color: var(--yellow);
    }
  }
}

h1.page-heading {
  @include desktop {
    font-size: 4em;
  }
}

h2 {
  font-size: 1.3rem;
  line-height: 1.1;
  @include desktop {
    font-size: 1.888rem;
  }
}
p {
  line-height: 1.5;
}
.large-text {
  font-size: 1.15rem;
  @include desktop {
    font-size: 1.5rem;
  }
}

.rich-text {
  figure {
    margin: 0;
  }
  figure img {
    width: 100%;
    height: auto;
  }
}

.quadrants__row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;

  // &:nth-child(2n + 1) {
  //   .quadrant:nth-child(1) {
  //     .quadrant__inner {
  //       &:after,
  //       &:before {
  //         background-color: blue;
  //       }
  //     }
  //   }
  // }
  // &:nth-child(2n) {
  //   .quadrant:nth-child(2) {
  //     .quadrant__inner {
  //       &:after,
  //       &:before {
  //         background-color: blue;
  //       }
  //     }
  //   }
  // }
}
.quadrant {
  position: relative;
  flex-basis: 50%;
  width: 50%;
  z-index: 2;
  margin: var(--quadrant-border-width);
  flex: 1;
  &.green .quadrant__inner {
    &:before,
    &:after {
      background-color: var(--green);
    }
  }
  &.yellow .quadrant__inner {
    &:before,
    &:after {
      background-color: var(--yellow);
    }
  }
  @include mobileonly {
    margin: 0;
  }
}
.quadrant__inner {
  display: block;
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;

  .quadrant__content {
    background-color: var(--white);
    box-sizing: border-box;
    padding: 1rem;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
    @include tablet {
      padding: 2rem;
    }
    @include desktop {
      padding: 3rem;
    }
  }
  &:before,
  &:after {
    z-index: 1;
    background-color: gray;
    position: absolute;
    content: "";
    display: block;
    width: calc(100% + var(--quadrant-border-width));
    height: calc(100% + var(--quadrant-border-width));
  }
  &:before {
    top: calc(-1 * var(--quadrant-border-width));
    left: calc(-1 * var(--quadrant-border-width));
  }
  &:after {
    bottom: calc(-1 * var(--quadrant-border-width));
    right: calc(-1 * var(--quadrant-border-width));
  }
}

.container.quadrants {
  @include mobileonly {
    background: var(--yellow);
    padding-top: 1rem;
    margin: 1rem 0;
  }
}
.mobile-quadrant {
  width: 100%;
  .mobile-quadrant__content {
    padding: 1rem 1.5rem;
  }
}

.slick-list {
  background-color: var(--white);
}
.rp-dots {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0 auto 1rem;
  padding: 0;
  text-align: center;

  li {
    display: inline-block;
    margin: 0.5rem;
  }
  .slick-active button {
    background-color: var(--black);
  }
  button {
    @include inputBorder;
  }
}

.btn--border {
  --btn-border-width: 12px;
  padding: 1rem 2rem;
  background-color: transparent;
  color: var(--dark-gray);
  position: relative;
  box-sizing: border-box;
  margin: var(--btn-border-width);

  border: 2px solid var(--white);
  transition: 0s;

  &:hover {
    border-color: var(--white);
    transition-delay: 0.5s;
    transition: 0.5s;

    &:before {
      top: calc(-1 * var(--btn-border-width) - 2px);
      left: auto;
      right: calc(-1 * var(--btn-border-width) - 2px);
      border-left: none;
      border-right: var(--btn-border-width) solid var(--dark-gray);
    }
    &:after {
      bottom: calc(-1 * var(--btn-border-width) - 2px);
      right: auto;
      left: calc(-1 * var(--btn-border-width) - 2px);
      border-right: none;
      border-left: var(--btn-border-width) solid var(--dark-gray);
    }
  }

  &:before,
  &:after {
    content: "";
    position: absolute;
    transition: 0.5s;
  }
  &:before {
    top: calc(-1 * var(--btn-border-width) - 1px);
    left: calc(-1 * var(--btn-border-width) - 1px);
    width: calc(100% + 0 * var(--btn-border-width));
    height: calc(100% + 0 * var(--btn-border-width));
    border-left: var(--btn-border-width) solid var(--dark-gray);
    border-top: var(--btn-border-width) solid var(--dark-gray);
  }
  &:after {
    bottom: calc(-1 * var(--btn-border-width) - 1px);
    right: calc(-1 * var(--btn-border-width) - 1px);
    width: calc(100% + 0 * var(--btn-border-width));
    height: calc(100% + 0 * var(--btn-border-width));
    border-right: var(--btn-border-width) solid var(--dark-gray);
    border-bottom: var(--btn-border-width) solid var(--dark-gray);
  }
}

.border-art {
  width: var(--border-art-width);
  height: 100%;

  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);

  border-right: 2px solid var(--white);
  background-size: contain;
  &.right {
    left: auto;
    right: 0;
    border-right: none;
    border-left: 2px solid var(--white);
  }
  @include desktop {
    background-image: url("/images/border-white.svg");
  }
  // @include desktop {
  //   width: 64px;
  // }
  // @include xl {
  //   width: 128px;
  // }
}
.flexBlock {
  position: relative;
}

.__react_component_tooltip {
  border-radius: 0;
  border-width: 2px !important;
  padding: 1rem;

  h3 {
    font-size: 1.11rem;
    margin: 0 0 0.5rem;
    padding-bottom: 0.1rem;
    border-bottom: 2px solid var(--yellow);
    display: inline-block;
  }
  ul {
    margin: 0.5rem 0 0 0;
    padding: 0;
    font-size: 1rem;
    list-style: none;
    li {
      position: relative;
      padding-left: 1rem;
      & + li {
        margin-top: 0.5em;
      }
      span {
        display: block;
        &.name {
          font-weight: 700;
        }
      }
    }
    li:before {
      content: "";
      position: absolute;
      background: var(--yellow);
      width: 8px;
      height: 8px;
      border-radius: 50%;
      left: 0;
      top: 0.5em;
      // transform: translate(0, -50%);
    }
  }

  &.place-right,
  &.place-left {
    // margin-top: 2rem;

    &:after,
    &:before {
      top: 15% !important;
    }
    &:before {
      border-width: 6px !important;
    }
  }
}

.outer {
  width: 100%;
  height: 100%;
  margin: 0 var(--border-art-width);
}

.slideout-content {
  color: var(--black);
  .image-block {
    height: 40vh;
    position: relative;
    img {
      height: 100%;
      width: 100%;
      position: absolute;
      top: 0;
      left: 0;
      object-fit: cover;
    }
  }
}
.slideout-content__inner {
  position: relative;
}

.eyebrow {
  font-size: 0.88rem;
  .flag {
    padding: 0.5rem;
    background-color: var(--yellow);
    text-transform: uppercase;
    font-weight: 700;
    display: inline-block;
  }
  .date {
    margin-left: 1rem;
  }
}

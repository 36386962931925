@import "~scss/variables";
.hello {
  background: var(--dark-gray);
  color: var(--white);
  padding: 0.9rem 1rem;
  text-transform: uppercase;
  text-align: center;
  overflow: hidden;
  a {
    color: var(--white);
    text-decoration: none;
    font-weight: 700;
  }
  font-size: 0.7rem;
  &.hide {
    display: none;

    & + header .nav {
      border-bottom: 2px solid var(--dark-gray);
    }
  }

  @include desktop {
    font-size: 0.9rem;
  }
}
.header {
  z-index: 10001;
  background-color: var(--white);
  position: sticky;

  top: -0.5px;

  a {
    transition: 0.5s;
  }
  a:hover {
    color: var(--green);
  }

  .nav {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-areas: "left mid right";
    padding: 1rem 1rem;
    z-index: 1001;
    position: relative;

    .nav-links {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: center;
      grid-area: right;
    }
    @include tablet {
      display: flex;
      justify-content: space-between;
      padding: 0.25rem 1rem;
      .nav-links {
        justify-content: center;
      }
    }
    @include desktop {
      display: grid;
      //grid-template-columns: var(--border-art-width) 1fr var(--border-art-width);
      grid-template-columns: var(--logo-width) 1fr var(--logo-width);
      padding: 1rem;
      .nav-links {
        grid-area: mid;
      }
    }
    @include large {
      padding: 0.68rem 0;
      align-items: center;
    }
  }

  .logo {
    position: relative;
    svg {
      width: 100%;
      height: auto;
    }
    &.logo--full {
      /* nothing */
      a {
        display: block;
        line-height: 0;
      }
    }
    a {
      position: relative;
    }
    @include tablet {
    }
    @include large {
      a {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translate(50%, -50%);
      }
      &.logo--full a {
        position: relative;
        transform: translate(0, 0);
        top: 0;
        right: calc(-1 * var(--border-art-width));
      }
    }
  }
  nav {
    a {
      color: var(--dark-gray);
      text-decoration: none;
      line-height: 2;

      &:hover,
      &.active {
        color: var(--green);
      }
    }

    @include desktop {
      a + a {
        margin-left: 1.5rem;
      }
    }
    @include large {
      a + a {
        margin-left: 2.2rem;
      }
    }
  }
}
.nav-links {
  nav {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content: center;
  }
}
.mobile-nav nav {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  z-index: 100;
  width: 100vw;
  top: 0;
  height: calc(100vh);
  z-index: 100;
  a {
    text-decoration: underline;
    font-size: 1.2rem;

    & + a {
      margin-top: 1.5rem;
      margin-left: 0;
    }
  }
}
.languages {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  @include desktop {
    margin-right: 2rem;
  }
}
.language-picker {
  display: inline-block;
  font-size: 0.72rem;
  font-weight: 700;
  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    // border: 2px solid var(--black);
    position: relative;

    z-index: 2;
    &:before,
    &:after {
      content: "";
      width: 100%;
      height: 100%;
      top: -2px;
      left: -2px;
      background-color: var(--dark-gray);
      position: absolute;
      z-index: 1;
    }
    &:after {
      top: 2px;
      left: 2px;
    }
    li {
      position: relative;
      background-color: var(--white);
      z-index: 2;
      margin: 0;
      padding: 0.68rem 0.44rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      &.active {
        background-color: var(--dark-gray);
      }
      & + li {
        border-left: 2px solid var(--dark-gray);
      }
    }
  }
}

@import "~scss/variables";

footer.page-footer {
  font-size: 1.1rem;

  a {
    color: var(--dark-gray);
    transition: 0.5s;
    &:hover {
      color: var(--green);
    }
  }
  .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    // @include mobileonly {
    //   margin: 0;
    // }
    @include tablet {
      flex-direction: row;
    }
  }

  .footer-top {
    background-color: var(--dark-gray);
    color: var(--white);
    padding: 1rem 0;

    .container {
      align-items: flex-start;
      @include tablet {
        align-items: center;
      }
    }

    a {
      color: var(--white);
      &:hover {
        color: var(--yellow);
      }
    }
    .mc-signup {
      margin-bottom: 0rem;
      div[class^="mc-"] {
        margin-top: 0.5em;
      }
      .mc-error {
        color: var(--red);
      }
    }
    h4 {
      margin: 0 0 0.5em;
      font-weight: 400;
    }
    input[type="text"],
    input[type="email"],
    input[type="submit"],
    button {
      font-size: 1em;
      background-color: transparent;
      border: 2px solid var(--white);
      padding: 0.5em 1rem;

      color: var(--white);
      font-family: var(--sans);
      box-sizing: border-box;
      &::placeholder {
        color: var(--white);
        font-family: var(--sans);
      }
    }

    input[type="submit"],
    button {
      background-color: var(--white);
      color: var(--black);
      border-radius: 0;
      text-transform: uppercase;
      margin-left: 0.5em;
    }
    .links {
      a {
        text-transform: uppercase;
        // text-decoration: none;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
      }
    }

    @include mobileonly {
      .mc-signup {
        margin-bottom: 2rem;
        & > div {
          margin: 1rem 0;
        }
        button {
          margin-left: 0;
          margin-top: 0.5rem;
        }
      }
      input[type="text"],
      input[type="email"],
      input[type="submit"] {
        margin-left: 0;
        margin: 0;
      }
      input[type="email"] {
        width: 100%;
      }
    }

    @include desktop {
      input[type="email"] {
        width: 288px;
      }
    }
  }

  .footer-bottom {
    padding: 2.66rem 0;

    ul.social-links {
      list-style: none;
      display: flex;
      justify-content: center;
      margin: 0;
      padding: 0;
      li {
        font-size: 1.8em;
        & + li {
          margin-left: 1.8rem;
        }
      }
      order: 2;
      @include tablet {
        justify-content: flex-start;
      }
    }
    .logos {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      height: 100%;
      position: relative;
      overflow: hidden;
      order: 3;
      margin-top: 2rem;
      a {
        flex-basis: 50%;
        svg {
          width: 100%;
        }
      }
      .vr {
        display: inline-block;
        margin: 0 1rem;
        width: 2px;
        height: 48px;
        background-color: var(--dark-gray);
      }
      @include tablet {
        order: 1;
        margin-top: 0;
      }
    }
  }
}

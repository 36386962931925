.btn {
  background-color: black;
  color: var(--white);
  text-decoration: none;
  padding: 2rem 4rem;
  transition: 0.5s;
  font-weight: 500;
  text-transform: uppercase;

  // &:hover {
  //   background-color: var(--yellow);
  // }
}

@mixin xl {
  @media (min-width: 1800px) {
    @content;
  }
}
@mixin large {
  @media (min-width: 1440px) {
    @content;
  }
}
@mixin desktop {
  @media (min-width: 1024px) {
    @content;
  }
}
@mixin tablet {
  @media (min-width: 768px) {
    @content;
  }
}

@mixin mobileonly {
  @media (max-width: 767px) {
    @content;
  }
}

@mixin inputBorder {
  width: 8px;
  height: 8px;
  background-color: var(--white);
  text-indent: -999999px;
  border: 2px solid var(--white);
  box-sizing: content-box;
  position: relative;
  padding: 0;
  display: inline-block;
  transition: background 0.5s;
  &:before,
  &:after {
    content: "";
    position: absolute;
  }
  &:before {
    top: -4px;
    left: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-left: 2px solid var(--black);
    border-top: 2px solid var(--black);
  }
  &:after {
    bottom: -4px;
    right: -4px;
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    border-right: 2px solid var(--black);
    border-bottom: 2px solid var(--black);
  }
  &:focus {
    outline: none;
  }
}
:root {
  --sans: covik-sans, sans-serif;
  --green: #00847b;
  --blue: #006eb8;
  --red: #c84d32;
  --yellow: #fdbe68;
  --white: #fffdf7;
  --black: #29241c;

  --dark-gray: #423e37;
  --logo-width: 137px;
  --tile-border-width: 32px;
  --location-border: 20px;

  --border-width: 16px;
  --border-art-width: 16px;
  --quadrant-border-width: 1.5rem;

  @include tablet {
    --border-width: 16px;
  }
  @include desktop {
    --logo-width: 150px;
    --border-width: 32px;
    --border-art-width: 32px;
    --quadrant-border-width: 2.5rem;
  }
  @include large {
    --logo-width: 170px;
    --border-width: 64px;
    --border-art-width: 64px;
    --quadrant-border-width: 3.5rem;
  }
  @include xl {
    --border-art-width: 128px;
  }
}
